import React, { Suspense, lazy } from 'react';
import { Switch, withRouter, Redirect, Route } from 'react-router-dom';
import { InternalToolFrame } from 'views/common/InternalFrame';
import { Loading } from '@shopify/polaris';

const OverviewPage = lazy(() => import('./views/tools/overview'));
const AccountsPage = lazy(() => import('./views/tools/accounts'));
const ContactsPage = lazy(() => import('./views/tools/contacts'));
const InvestorAccountsPage = lazy(() =>
  import('./views/tools/investor-accounts')
);
const OfferingsPage = lazy(() => import('./views/tools/offerings'));
const InvestmentEntitiesPage = lazy(() =>
  import('./views/tools/investment-entities')
);
const InvestmentsPage = lazy(() => import('./views/tools/investments'));
const DistributionsPage = lazy(() => import('./views/tools/distributions'));
const SubscriptionPackagesPage = lazy(() =>
  import('./views/tools/subscription-packages')
);
const DocumentsPage = lazy(() => import('./views/tools/documents'));
const DocumentsResultPage = lazy(() =>
  import('./views/tools/documents/results')
);
const UsersPage = lazy(() => import('./views/tools/users'));
const DataTransfersPage = lazy(() => import('./views/transfers'));
const DataTransfersDetailPage = lazy(() => import('./views/transfers/detail'));
const ImportsDocumentMatching = lazy(() =>
  import('./views/transfers/document/matching')
);

function AppRouter() {
  return (
    <InternalToolFrame>
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* Redirect to cannonical route */}
          <Redirect exact path="/" to="/overview" />
          {/* Single-route resources */}
          <Route path="/overview" component={OverviewPage} />
          <Route path="/portals" component={AccountsPage} />
          <Route path="/contacts" component={ContactsPage} />
          <Route path="/investor-accounts" component={InvestorAccountsPage} />
          <Route path="/offerings" component={OfferingsPage} />
          <Route
            path="/investment-entities"
            component={InvestmentEntitiesPage}
          />
          <Route path="/investments" component={InvestmentsPage} />
          <Route path="/distributions" component={DistributionsPage} />
          <Route
            path="/subscription-packages"
            component={SubscriptionPackagesPage}
          />
          <Route
            path="/documents/:accountId/results"
            component={DocumentsResultPage}
          />
          <Route path="/documents" component={DocumentsPage} />
          <Route path="/users" component={UsersPage} />
          <Route path="/transfers/:id" component={DataTransfersDetailPage} />
          <Route path="/transfers" component={DataTransfersPage} />
          <Route
            path="/document-matching"
            component={ImportsDocumentMatching}
          />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    </InternalToolFrame>
  );
}

export default withRouter(AppRouter);
