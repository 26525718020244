import React, { useCallback, useEffect, useState } from 'react';
import {
  LogOutMinor,
  UpdateInventoryMajor,
  ManagedStoreMajor,
  CustomersMajor,
  CategoriesMajor,
  NatureMajor,
  AffiliateMajor,
  CashDollarMajor,
  OutgoingMajor,
  SoftPackMajor,
  FolderMajor,
  ProfileMajor,
  ImportStoreMajor,
} from '@shopify/polaris-icons';
import { Frame, TopBar, Navigation } from '@shopify/polaris';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';

type Props = {
  children: JSX.Element;
};

export const AppFrame = ({ children }: Props) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [firstName, setFirstName] = useState('');
  const location = useLocation();

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen(isOpen => !isOpen),
    []
  );

  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive(active => !active),
    []
  );

  useEffect(() => {
    Auth.currentUserInfo().then(({ attributes }) => {
      if (!attributes) return;
      setFirstName(attributes.given_name);
    });
  }, [setFirstName]);

  const signOut = useCallback(async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error('error signing out: ', error);
    }
  }, []);

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Logout',
              icon: LogOutMinor,
              onAction: signOut,
            },
          ],
        },
      ]}
      name={firstName}
      initials={firstName.charAt(0)}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const navigationMarkup = (
    <>
      <Navigation location={location.pathname}>
        <Navigation.Section
          items={[
            {
              label: 'Overview',
              icon: UpdateInventoryMajor,
              selected: location.pathname.startsWith('/overview'),
              url: '/overview',
            },
            {
              label: 'Portals',
              icon: ManagedStoreMajor,
              selected: location.pathname.startsWith('/portals'),
              url: '/portals',
            },
            {
              label: 'Contacts',
              icon: CustomersMajor,
              selected: location.pathname.startsWith('/contacts'),
              url: '/contacts',
            },
            {
              label: 'Investor accounts',
              icon: CategoriesMajor,
              selected: location.pathname.startsWith('/investor-accounts'),
              url: '/investor-accounts',
            },
            {
              label: 'Offering',
              icon: NatureMajor,
              selected: location.pathname.startsWith('/offerings'),
              url: '/offerings',
            },
            {
              label: 'Investment entities',
              icon: AffiliateMajor,
              selected: location.pathname.startsWith('/investment-entities'),
              url: '/investment-entities',
            },
            {
              label: 'Investments',
              icon: CashDollarMajor,
              selected: location.pathname.startsWith('/investments'),
              url: '/investments',
            },
            {
              label: 'Distributions',
              icon: OutgoingMajor,
              selected: location.pathname.startsWith('/distributions'),
              url: '/distributions',
            },
            {
              label: 'Subscription Packages',
              icon: SoftPackMajor,
              selected: location.pathname.startsWith('/subscription-packages'),
              url: '/subscription-packages',
            },
            {
              label: 'Documents',
              icon: FolderMajor,
              selected: location.pathname.startsWith('/documents'),
              url: '/documents',
            },
            {
              label: 'Users',
              icon: ProfileMajor,
              selected: location.pathname.startsWith('/users'),
              url: '/users',
            },
          ]}
        />
        <Navigation.Section
          separator
          title="APPS"
          items={[
            {
              label: 'Import / Export',
              icon: ImportStoreMajor,
              selected: location.pathname.startsWith('/transfers'),
              url: '/transfers',
            },
          ]}
        />
      </Navigation>
    </>
  );

  return (
    <Frame
      topBar={topBarMarkup}
      navigation={navigationMarkup}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
    >
      {children}
    </Frame>
  );
};
