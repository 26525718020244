/* eslint-disable no-param-reassign */
import { LinkLikeComponentProps } from '@shopify/polaris/dist/types/latest/src/utilities/link';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

const StyleFixer = createGlobalStyle`
  a {
    color: ${({ theme }) => theme.colors.primary}
  }
`;

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

export const Link = ({
  children,
  url = '',
  external,
  ref,
  ...rest
}: LinkLikeComponentProps) => {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbitrary links, so anything that is not a path-based link should
  // use a regular old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = '_blank';
    rest.rel = 'noopener noreferrer';
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <>
      <StyleFixer />
      <ReactRouterLink to={url} {...rest}>
        {children}
      </ReactRouterLink>
    </>
  );
};
