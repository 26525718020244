import React from 'react';
import { ReactElementLike } from 'prop-types';
import { AppProvider } from '@shopify/polaris';
import theme from 'styles/theme';
import { ThemeConfig } from '@shopify/polaris/dist/types/latest/src/utilities/theme';
import gbInternalToolslogo from 'assets/internal-app-logo.png';
import { Link } from 'components/Navigation/Link';
import translations from '@shopify/polaris/locales/en.json';

type Props = {
  children: ReactElementLike;
};

export const InternalAppProvider = ({ children }: Props) => {
  const appTheme: ThemeConfig = {
    colors: {
      ...theme.colors,
      topBar: {
        background: theme.colors.background,
        backgroundLighter: theme.colors.backgroundLighter,
        backgroundDarker: theme.colors.backgroundDarker,
        border: theme.colors.border,
        color: theme.colors.primary,
      },
    },
    logo: {
      width: 120,
      topBarSource: gbInternalToolslogo,
      contextualSaveBarSource: gbInternalToolslogo,
      url: '/',
      accessibilityLabel: 'Groundbreaker Internal Tools',
    },
  };

  return (
    <AppProvider theme={appTheme} i18n={translations} linkComponent={Link}>
      {children}
    </AppProvider>
  );
};
