import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import Amplify from 'aws-amplify';
import { ThemeProvider } from 'styled-components';

import theme from 'styles/theme';
import GlobalStyles from 'styles/global';
import PolarisStyles from 'styles/polaris';
import '@shopify/polaris/dist/styles.css';
import Routes from 'routes';
import ScrollToTop from 'components/ScrollToTop';

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    clientMetadata: {
      PORTAL: 'internal',
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_UPLOAD_BUCKET,
      region: 'us-east-1',
    },
  },
  API: {
    endpoints: [
      {
        name: 'internal',
        endpoint: process.env.REACT_APP_INTERNAL_API_ENDPOINT,
      },
    ],
  },
});

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [user, setUser] = React.useState<unknown>();

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <ThemeProvider theme={theme}>
      <PolarisStyles />
      <GlobalStyles />
      <BrowserRouter>
        <ScrollToTop />
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  ) : (
    <div className="App">
      <AmplifyAuthenticator>
        <AmplifySignIn
          headerText="Groundbreaker Internal"
          slot="sign-in"
          hideSignUp
        />
      </AmplifyAuthenticator>
    </div>
  );
};

export default AuthStateApp;
