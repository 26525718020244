export default {
  grid: {
    container: '130rem',
    gutter: '3.2rem',
  },
  border: {
    radius: '0.4rem',
  },
  font: {
    family:
      "-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, Helvetica Neue, sans-serif",
    light: 300,
    normal: 400,
    bold: 600,
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
    },
  },
  colors: {
    primary: '#212B36',
    secondary: '#C4CDD5',
    border: '#C4CDD5',
    background: '#FFFFFF',
    backgroundLighter: '#F4F6F8',
    backgroundDarker: '#DFE3E8',

    // Old theme colorMap
    blackBlue: '#001837',
    blueGray: '#5b6b7f',
    blueGrayLight: '#98a8bc',
    gray: '#dee1e5',
    grayLight: '#efefef',
    grayLighter: '#fbfaf9',
    grayBackground: '#f9f9f9',
    blueLighter: '#6891ff',
    blueLight: '#3e72ff',
    blue: '#003cde',
    blueDark: '#001a60',
    blueDarker: '#00103c',
    darkGray: '#7a838e',
    green: '#50b83c',
    lightGreen: '#b7e2af',
    yellow: '#ffae02',
    red: '#f75c43',
    // unknown colors
    darkest: '#212B36',
    transparentBackground: 'rgba(99, 114, 130, 0.5)',
    selago: '#f3f6fe',
    orange: '#fbd687',
    zircon: '#edf2ff',
    lightRed: '#f9bc85',
    white: '#FAFAFA',
    black: '#030517',
    lightGray: '#EAEAEA',
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
};
