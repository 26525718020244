import React from 'react';
import { ReactElementLike } from 'prop-types';
import { InternalAppProvider } from '../InternalAppProvider';
import { AppFrame } from '../AppFrame';

type Props = {
  children: ReactElementLike;
};

export const InternalToolFrame = ({ children }: Props) => (
  <InternalAppProvider>
    <AppFrame>{children}</AppFrame>
  </InternalAppProvider>
);
