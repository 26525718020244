import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as H from 'history';
import { withRouter } from 'react-router-dom';

type Props = {
  location: H.Location;
};

// componentDidUpdate previous props value
const usePrevious = (value: Props): Props | undefined => {
  const ref = useRef<Props>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const ScrollToTop = ({ location }: Props) => {
  const mounted = useRef<boolean>();
  const prevProps = usePrevious({ location });

  useEffect(() => {
    if (!mounted.current) {
      // componentDidMount
      mounted.current = true;
      // componentDidUpdate
    } else if (location.pathname !== prevProps?.location.pathname) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  });

  return <></>;
};

export default withRouter(ScrollToTop);
