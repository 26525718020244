import { createGlobalStyle, css } from 'styled-components';

const PolarisStyles = createGlobalStyle`
  ${({ theme }) => css`
    .Polaris-Avatar {
      background: var(--p-action-secondary, ${theme.colors.backgroundDarker});
      color: var(--p-icon-subdued, #919eab);
    }

    .Polaris-Avatar__Image {
      background-color: var(--p-background, ${theme.colors.backgroundDarker});
    }

    .Polaris-Icon--colorSky svg {
      fill: ${theme.colors.backgroundDarker};
    }

    .Polaris-Icon--colorSkyDark svg {
      fill: ${theme.colors.secondary};
    }

    .Polaris-Icon--colorInkLighter::before {
      background-color: var(--p-surface, ${theme.colors.backgroundDarker});
    }

    .Polaris-Icon--colorInk svg {
      fill: ${theme.colors.primary};
    }

    .Polaris-Icon--colorInk::before {
      background-color: var(--p-surface, ${theme.colors.backgroundDarker});
    }

    .Polaris-Icon--colorIndigo svg {
      fill: ${theme.colors.blue};
    }

    .Polaris-Navigation__Item {
      color: var(--p-text, ${theme.colors.primary});
    }

    .Polaris-Navigation__Item:hover {
      color: var(--p-text, #143aa2);
    }

    .Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg {
      fill: ${theme.colors.blue};
    }

    .Polaris-Navigation__Item--selected {
      font-weight: 600;
      color: var(--p-action-primary, #143aa2);
      background-color: var(--p-background-selected, #edeeef);
    }

    .Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg {
      fill: ${theme.colors.blue};
    }

    .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon svg {
      fill: ${theme.colors.blue};
    }

    .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg {
      fill: ${theme.colors.blue};
    }

    .Polaris-Navigation__Item:focus .Polaris-Navigation__Icon svg {
      fill: var(--p-icon, ${theme.colors.blue});
      color: var(--p-override-transparent, white);
    }

    .Polaris-Tabs__Tab:focus .Polaris-Tabs__Title {
      color: var(--p-text, ${theme.colors.primary});
      border-bottom: var(--p-override-none, 0.3rem) solid #b3bcf5;
    }

    .Polaris-Tabs__Tab.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
      border-bottom: var(--p-override-none, 0.3rem) solid ${theme.colors.blue};
    }

    .Polaris-TopBar {
      border-bottom: 1px solid #dfe3e8;
    }

    .Polaris-ResourceItem:active:not(.Polaris-ResourceItem--newDesignLanguage) {
      background-image: linear-gradient(#fafafa, #fafafa);
    }

    .Polaris-Button--primary:not(.Polaris-Button--destructive):not(.Polaris-Button--disabled) {
      background: ${theme.colors.blue};
      border-color: ${theme.colors.blueDark};
      &:hover {
        background: ${theme.colors.blue};
      }
    }

    .Polaris-RadioButton__Icon,
    .Polaris-RadioButton__Backdrop {
      background: ${theme.colors.blue} !important;
    }

    .Polaris-Button--primary:focus {
      border-color: ${theme.colors.blue};
      box-shadow: inset 0 1px 0 0 #003cde, 0 0 0 1px #003cde;
    }

    .Polaris-TextField__Backdrop::after {
      border: var(--p-override-none, 0.2rem solid ${theme.colors.blueDark});
    }
  `}
`;

export default PolarisStyles;
